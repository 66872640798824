$icon-font-path: '../fonts/';

// bower:scss
// Core variables and mixins
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";

// Core CSS
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;

  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 14px 24px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.spinner {
  display: inline-block;
  opacity: 0;
  width: 0;

  -webkit-transition: opacity 0.25s, width 0.25s;
  -moz-transition: opacity 0.25s, width 0.25s;
  -o-transition: opacity 0.25s, width 0.25s;
  transition: opacity 0.25s, width 0.25s;
}

.has-spinner.is-active {
  cursor:progress;
}

.has-spinner.is-active .spinner {
  opacity: 1;
    width: 16px;
}

.has-spinner.btn-mini.is-active .spinner {
    width: 10px;
}

.has-spinner.btn-small.is-active .spinner {
    width: 13px;
}

.has-spinner.btn.is-active .spinner {
    width: 16px;
}

.has-spinner.btn-large.is-active .spinner {
    width: 19px;
}

.form-control {
    border-radius: 0px;
}

.btn {
    border-radius: 0px;
}

.input-group-addon {
    border-radius: 0px;
}
